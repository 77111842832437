import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitch,
  faYoutube,
  faTwitter,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import { mainFooter, logo } from '../styles/footer.module.scss';

const Footer = () => (
  <footer className={`${mainFooter} footer-paint`}>
    <div>
      <div>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCh2uNAGmfnHMgDoZ2gQywBQ"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faYoutube} />
        </a>
        <a
          target="_blank"
          href="https://twitter.com/theveiledflame"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </div>
      <div>
        <Link to="/" className={logo}>
          <StaticImage
            alt="The Veiled Flame Logo"
            src="../images/vf-logo-solo-2021-white.png"
            loading="eager"
            placeholder="none"
          />
        </Link>
      </div>
      <div>
        <a
          target="_blank"
          href="https://www.instagram.com/theveiledflame/"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a
          target="_blank"
          href="https://www.twitch.tv/theveiledflame"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faTwitch} />
        </a>
      </div>
      <span>
        ©{new Date().getFullYear()} The Veiled Flame
      </span>
    </div>
  </footer>
);

export default Footer;
