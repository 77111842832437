import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Header from './header';
import Footer from './footer';
import ResponsiveMenu from './responsive-menu';

// import "@fortawesome/react-fontawesome"
// import '../styles/global.scss';
import '../styles/layout.module.scss';

const Layout = ({ pageTitle, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <div id="outer-container">
      <title>
        {pageTitle} | {data.site.siteMetadata.title}
      </title>

      <ResponsiveMenu />

      <main id="content-container">
        <Header />
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
