import React from 'react';
import { AnimatePresence } from 'framer-motion';
import Layout from './src/components/layout';
import '@fontsource/source-sans-pro';
import './src/styles/global.scss';
import { ContactProvider } from './src/components/contact-context';

// export const shouldUpdateScroll = () => false;

export const shouldUpdateScroll = ({
  routerProps: { location },

  getSavedScrollPosition,
}) => {
  // transition duration from `layout.js` * 1000 to get time in ms

  // * 2 for exit + enter animation

  const TRANSITION_DELAY = 0.2 * 1000 * 2;

  // console.log('THE LOCATION: ', location);

  // if it's a "normal" route

  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY);
  } else if (location.pathname.startsWith('/reviews/review-type/')) {
    return false;
  }

  // if we used the browser's forwards or back button
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0];

    window.setTimeout(
      () => window.scrollTo(...savedPosition),
      TRANSITION_DELAY
    );
  }

  return false;
};

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>
    <AnimatePresence mode='wait'>{element}</AnimatePresence>
  </Layout>
);

export const wrapRootElement = ({ element }) => (
  <ContactProvider>{element}</ContactProvider>
);
