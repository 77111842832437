import React, { useState } from 'react';

const ContactContext = React.createContext();

export function ContactProvider({ children }) {
  // Set State
  // console.log(defaults);
  const [contacts, setContacts] = useState([]);
  // const [values, setValues] = useState([]);
  return (
    <ContactContext.Provider value={[contacts, setContacts]}>
      {children}
    </ContactContext.Provider>
  );
}

export default ContactContext;
