exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-contentful-blog-slug-js": () => import("./../../../src/pages/blog/{contentfulBlog.slug}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-slug-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reviews-contentful-review-slug-js": () => import("./../../../src/pages/reviews/{contentfulReview.slug}.js" /* webpackChunkName: "component---src-pages-reviews-contentful-review-slug-js" */),
  "component---src-pages-reviews-index-js": () => import("./../../../src/pages/reviews/index.js" /* webpackChunkName: "component---src-pages-reviews-index-js" */)
}

