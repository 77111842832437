import React, { useState } from 'react';
import { reveal as Menu } from 'react-burger-menu';

import { Link } from 'gatsby';
import '../styles/responsive-menu.scss';

const ResponsiveMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Menu
      width="70%"
      right
      pageWrapId="content-container"
      outerContainerId="outer-container"
      isOpen={menuOpen}
      onStateChange={(state) => handleStateChange(state)}
    >
      <Link
        to="/"
        onClick={() => {
          closeMenu();
        }}
      >
        Home
      </Link>

      <Link
        to="/blog"
        onClick={() => {
          closeMenu();
        }}
      >
        Blog
      </Link>

      <Link
        to="/reviews"
        onClick={() => {
          closeMenu();
        }}
      >
        Reviews
      </Link>

      <Link
        to="/contact"
        onClick={() => {
          closeMenu();
        }}
      >
        Contact
      </Link>

      <a
        target="_blank"
        href="https://twitch.tv/theveiledflame"
        rel="noreferrer"
      >
        Twitch
      </a>
    </Menu>
  );
};

export default ResponsiveMenu;
