import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import '../styles/header.scss';

const Header = () => (
  <>
    <header className="header-container">
      <ul>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to="/reviews">Reviews</Link>
        </li>
        <li>
          <Link to="/" className="logo">
            <StaticImage
              alt="The Veiled Flame Logo"
              src="../images/vf-logo.svg"
              loading="eager"
              placeholder="none"
            />
          </Link>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
        <li>
          <a
            target="_blank"
            href="https://twitch.tv/theveiledflame"
            rel="noreferrer"
          >
            Twitch
          </a>
        </li>
      </ul>

      {/* <ResponsiveMenu /> */}
    </header>
  </>
);

export default Header;
